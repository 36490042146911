<template>
  <main style="max-width: 70vw">
    <h1>Program</h1>
    <body>
    <!--  <h1>TIME TABLE</h1>-->

    The full program is available on the <a href="https://programs.sigchi.org/tei/2025/" target="_blank"> SIGCHI's
      program application</a>. <br> <br>
    <!--<p style="text-align: center"><strong>This program is still provisional and some changes may occur. Particularly, starting and end times of sessions may change.</strong></p>-->
    <h2>Program Overview</h2>

    <div class="schedule">
<!--      COLUMN = days-->
      <span class="track-slot" aria-hidden="true" style="grid-column: tuesday; grid-row: tracks;">Tuesday</span>
      <span class="track-slot" aria-hidden="true" style="grid-column: wednesday; grid-row: tracks;">Wednesday</span>
      <span class="track-slot" aria-hidden="true" style="grid-column: thursday; grid-row: tracks;">Thursday</span>
      <span class="track-slot" aria-hidden="true" style="grid-column: friday; grid-row: tracks;">Friday</span>
<!--ROWS-->
      <span class="time-slot" style="grid-row: time-0800;">8:00am</span>
      <span class="time-slot" style="grid-row: time-0900;">9:00am</span>
      <span class="time-slot" style="grid-row: time-1000;">10:00am</span>
      <span class="time-slot" style="grid-row: time-1100;">11:00am</span>
      <span class="time-slot" style="grid-row: time-1200;">12:00pm</span>
      <span class="time-slot" style="grid-row: time-1300;">1:00pm</span>
      <span class="time-slot" style="grid-row: time-1400;">2:00pm</span>
      <span class="time-slot" style="grid-row: time-1500;">3:00pm</span>
      <span class="time-slot" style="grid-row: time-1600;">4:00pm</span>
      <span class="time-slot" style="grid-row: time-1700;">5:00pm</span>
      <span class="time-slot" style="grid-row: time-1800;">6:00pm</span>
      <span class="time-slot" style="grid-row: time-1900;">7:00pm</span>
      <span class="time-slot" style="grid-row: time-2000;">8:00pm</span>
      <span class="time-slot" style="grid-row: time-2100;">9:00pm</span>
      <span class="time-slot" style="grid-row: time-2200;">10:00pm</span>
      <span class="time-slot" style="grid-row: time-2300;">11:00pm</span>

<!--    LUNCH-->
      <div class="session main tuesday" style="grid-column: thursday / friday; grid-row: time-1245 / time-1400;">
        <h4 class="session-title" style="text-align: left">Lunch</h4>
      </div>
<!--      REGISTRATION-->
      <div class="session events tuesday" style="grid-column: tuesday / friday; grid-row: time-0800 / time-0830;">
        <h4 class="session-title">Registration</h4>
      </div>

<!--      TUESDAY -->
      <div class="session studios tuesday" style="grid-column: tuesday; grid-row: time-0900 / time-1700;">
          <h4 class="session-title">Studios and GSC</h4>
          <span class="session-time">9:00am - 5:00pm</span>
        </div>
      <div class="session events tuesday" style="grid-column: tuesday; grid-row: time-1800 / time-2000;">
        <h4 class="session-title">TEI'25 welcome reception</h4>
        <span class="session-time">6:00pm - 8:00m</span>
      </div>

<!--      WEDNESDAY-->
      <div class="session main wednesday" style="grid-column: wednesday; grid-row: time-0830 / time-0900;">
        <h4 class="session-title">Opening</h4>
        <span class="session-time">08:30am - 09:00am</span>
      </div>
      <div class="session main wednesday" style="grid-column: wednesday; grid-row: time-0900 / time-1000;">
        <h4 class="session-title">Opening Keynote</h4>
        <span class="session-time">09:00am - 10:00am</span>
      </div>
      <div class="session main wednesday" style="grid-column: wednesday; grid-row: time-1000 / time-1015;">
        <h4 class="session-title">Flash Presentations</h4>
        <span class="session-time">10:00am - 10:19am</span>
      </div>
      <div class="session main wednesday" style="grid-column: wednesday; grid-row: time-1015 / time-1100;">
        <h4 class="session-title">Posters #1</h4>
        <span class="session-time">10:19am - 11:00am</span>
      </div>
      <div class="session papers wednesday" style="grid-column: wednesday; grid-row: time-1100 / time-1230;">
        <h4 class="session-title">Tangible Textiles</h4>
        <span class="session-time">11:00am - 12:00pm</span>
      </div>
      <div class="session events wednesday" style="grid-column: wednesday; grid-row: time-1245 / time-1400;">
        <h4 class="session-title">Diversity Lunch</h4>
        <span class="session-time">12:45pm - 2:00pm</span>
        
      </div>
      <div class="session papers wednesday" style="grid-column: wednesday; grid-row: time-1400 / time-1530;">
        <h4 class="session-title">Haptics</h4>
        <span class="session-time">2:00pm - 3:30pm</span>
        
      </div>
      <div class="session main wednesday" style="grid-column: wednesday; grid-row: time-1530 / time-1600;">
        <h4 class="session-title">Posters #1</h4>
        <span class="session-time">3:30pm - 4:00pm</span>
        
      </div>
      <div class="session papers wednesday" style="grid-column: wednesday; grid-row: time-1600 / time-1745;">
        <h4 class="session-title">Spirituality, Nature, and Place</h4>
        <span class="session-time">4:00pm - 5:45pm</span>
        
      </div>
      <div class="session SDC wednesday" style="grid-column: wednesday; grid-row: time-1800 / time-2000;">
        <h4 class="session-title">Student Design Competition</h4>
        <span class="session-time">6:00pm - 8:00pm</span>
        
      </div>

<!--      THURSDAY -->
      <div class="session papers thursday" style="grid-column: thursday; grid-row: time-0830 / time-1015;">
        <h4 class="session-title">Physicalization</h4>
        <span class="session-time">8:30am - 10:15am</span>
        
      </div>
      <div class="session main thursday" style="grid-column: thursday; grid-row: time-1015 / time-1030;">
        <h4 class="session-title">Flash presentations</h4>
        <span class="session-time">10:15am - 10:30am</span>
        
      </div>
      <div class="session main thursday" style="grid-column: thursday; grid-row: time-1030 / time-1100;">
      <h4 class="session-title">Posters #2</h4>
        <span class="session-time">10:30am - 11:00am</span>
        
    </div>
      <div class="session papers thursday" style="grid-column: thursday; grid-row: time-1100 / time-1230;">
        <h4 class="session-title">Elemental & Biomaterial</h4>
        <span class="session-time">11:00am - 12:30pm</span>
        
      </div>
      <div class="session papers thursday" style="grid-column: thursday; grid-row: time-1400 / time-1545;">
        <h4 class="session-title">Paper-based Interaction</h4>
        <span class="session-time">2:00pm - 3:45pm</span>
        
      </div>
      <div class="session events thursday" style="grid-column: thursday; grid-row: time-1900 / time-0000;">
        <h4 class="session-title">Demos and <br> Arts & Performance</h4>
        <span class="session-time">7pm - 12am</span>
        
      </div>


      <!--      FRIDAY -->
      <div class="session papers friday" style="grid-column: friday; grid-row: time-0830 / time-1015;">
        <h4 class="session-title">Sensors and Actuators</h4>
        <span class="session-time">8:30am - 10:15am</span>
        
      </div>
      <div class="session main friday" style="grid-column: friday; grid-row: time-1015 / time-1030;">
        <h4 class="session-title">Flash presentations</h4>
        <span class="session-time">10:15am - 10:30am</span>
        
      </div>
      <div class="session main friday" style="grid-column: friday; grid-row: time-1030 / time-1100;">
        <h4 class="session-title">Posters #3</h4>
        <span class="session-time">10:30am - 11:00am</span>
        
      </div>
      <div class="session papers friday" style="grid-column: friday; grid-row: time-1100 / time-1245;">
        <h4 class="session-title">The More, the Merrier</h4>
        <span class="session-time">11:00am - 12:45pm</span>
        
      </div>
      <div class="session events friday" style="grid-column: friday; grid-row: time-1400 / time-1500;">
        <h4 class="session-title">TEI townhall</h4>
        <span class="session-time">2:00pm - 3:00pm</span>
        
      </div>
      <div class="session main thursday" style="grid-column: friday; grid-row: time-1500 / time-1530;">
        <h4 class="session-title">Posters #3</h4>
        <span class="session-time">3:00pm – 3:30pm</span>
        
      </div>
      <div class="session main friday" style="grid-column: friday; grid-row: time-1530 / time-1700;">
        <h4 class="session-title">Closing panel</h4>
        <span class="session-time">3:30pm – 5:00pm</span>
        
      </div>
      <div class="session main friday" style="grid-column: friday; grid-row: time-1700 / time-1730;">
        <h4 class="session-title">Closing and TEI'26 announcement</h4>
        <span class="session-time">5:00pm – 5:30pm</span>
        
      </div>
<!--      </div>-->
<!--      <div class="session papers wednesday" style="grid-column: wednesday; grid-row: time-1600 / time-1745;">-->
<!--        <h4 class="session-title">Spirituality, Nature, and Place</h4>-->
<!--        <span class="session-time">4:00pm - 5:45pm</span>-->
<!--        <span class="session-track"></span>-->
<!--        <span class="session-presenter"></span>-->
<!--      </div>-->
<!--      <div class="session SDC wednesday" style="grid-column: wednesday; grid-row: time-1800 / time-2000;">-->
<!--        <h4 class="session-title">Student Design Competition</h4>-->
<!--        <span class="session-time">6:00pm - 8:00pm</span>-->
<!--        <span class="session-track"></span>-->
<!--        <span class="session-presenter"></span>-->
<!--      </div>-->


        <!-- details -->
<!--      </div>-->
<!--      <div class="session session-2 wednesday" style="grid-column: wednesday; grid-row: time-1015 / time-1100">-->
<!--        &lt;!&ndash; details &ndash;&gt;-->
<!--        <h4 class="session-title"><a href="#">Posters #1</a></h4>-->
<!--        <span class="session-time">10:19am - 11:00am</span>-->
<!--        <span class="session-track">Track 1</span>-->
<!--        <span class="session-presenter">Presenter Name</span>-->
<!--      </div>-->
<!--      <div class="session session-2 thursday" style="grid-column: thursday; grid-row: time-0900 / time-1000">-->
<!--        &lt;!&ndash; details &ndash;&gt;-->
<!--        <h4 class="session-title"><a href="#">Session Title</a></h4>-->
<!--        <span class="session-time">8:00am - 9:00am</span>-->
<!--        <span class="session-track">Track 1</span>-->
<!--        <span class="session-presenter">Presenter Name</span>-->
<!--      </div>-->
<!--      <div class="session session-2 friday" style="grid-column: friday; grid-row: time-0900 / time-1000">-->
<!--        &lt;!&ndash; details &ndash;&gt;-->
<!--        <h4 class="session-title"><a href="#">Session Title</a></h4>-->
<!--        <span class="session-time">8:00am - 9:00am</span>-->
<!--        <span class="session-track">Track 1</span>-->
<!--        <span class="session-presenter">Presenter Name</span>-->
<!--      </div>-->

<!--      <div class="session session-2 friday" style="grid-column: tuesday / thursday; grid-row: time-0900 / time-1000">-->
<!--        &lt;!&ndash; details &ndash;&gt;-->
<!--        <h4 class="session-title"><a href="#">Session Title</a></h4>-->
<!--        <span class="session-time">8:00am - 9:00am</span>-->
<!--        <span class="session-track">Track 1</span>-->
<!--        <span class="session-presenter">Presenter Name</span>-->
<!--      </div>-->
<!--      <div class="session session-2 wednesday" style="grid-column: tuesday; grid-row: time-0900 / time-1000">-->
<!--        &lt;!&ndash; details &ndash;&gt;-->
<!--        <h4 class="session-title"><a href="#">Session Title</a></h4>-->
<!--        <span class="session-time">8:00am - 9:00am</span>-->
<!--        <span class="session-track">Track 1</span>-->
<!--        <span class="session-presenter">Presenter Name</span>-->
<!--      </div>-->
<!--      <h3 class="time-slot">8:00am</h3>-->

      <!-- Sessions 2, 3, 4 -->

<!--      <h3 class="time-slot">9:00am</h3>-->
<!--      <div class="session session-5 track-1">-->
<!--        <h4 class="session-title"><a href="#">Session Title</a></h4>-->
<!--        <span class="session-time">9:00am - 10:00am</span>-->
<!--        <span class="session-track">Track 1</span>-->
<!--        <span class="session-presenter">Presenter Name</span>-->
<!--      </div>-->
      <!-- Sessions 6, 7, 8 -->

      <!-- etc... -->

    </div>
    <!--  <table>-->
    <!--    <tr>-->
    <!--      <th></th>-->
    <!--      <th>Tuesday, 4th</th>-->
    <!--      <th>Wednesday, 5th</th>-->
    <!--      <th>Thursday, 6th</th>-->
    <!--      <th>Friday, 7th</th>-->
    <!--&lt;!&ndash;      <th>I<br>9:30-10:20</th>&ndash;&gt;-->
    <!--&lt;!&ndash;      <th>II<br>10:20-11:10</th>&ndash;&gt;-->
    <!--&lt;!&ndash;      <th>III<br>11:10-12:00</th>&ndash;&gt;-->
    <!--&lt;!&ndash;      <th>12:00-12:40</th>&ndash;&gt;-->
    <!--&lt;!&ndash;      <th>IV<br>12:40-1:30</th>&ndash;&gt;-->
    <!--&lt;!&ndash;      <th>V<br>1:30-2:20</th>&ndash;&gt;-->
    <!--&lt;!&ndash;      <th>VI<br>2:20-3:10</th>&ndash;&gt;-->
    <!--&lt;!&ndash;      <th>VII<br>3:10-4:00</th>&ndash;&gt;-->
    <!--    </tr>-->
    <!--    <tr>-->
    <!--      <td class="highlight"><b>8:00</b></td>-->
    <!--      <td colspan="4" class="special">Registration</td>-->
    <!--    </tr>-->
    <!--    <tr>-->
    <!--      <td class="highlight"><b>8:30–10:15</b></td>-->
    <!--      <td>Studios and GSC session</td>-->
    <!--      <td>Opening & <a href="https://tei.acm.org/2025/program/keynote_speaker">Keynote</a></td>-->
    <!--      <td>Session: Physicalization</td>-->
    <!--      <td>Session: Sensors and Actuators</td>-->
    <!--&lt;!&ndash;      <td class="special">SPORTS</td>&ndash;&gt;-->
    <!--    </tr>-->
    <!--    <tr>-->
    <!--&lt;!&ndash;      <td class="highlight"><b>10:00–10:15</b></td>&ndash;&gt;-->
    <!--&lt;!&ndash;      <td></td>&ndash;&gt;-->
    <!--&lt;!&ndash;      <td>Lightning talk posters</td>&ndash;&gt;-->
    <!--&lt;!&ndash;      <td>Lightning talk posters</td>&ndash;&gt;-->
    <!--&lt;!&ndash;      <td>Lightning talk posters</td>&ndash;&gt;-->
    <!--    </tr>-->
    <!--    <tr>-->
    <!--      <td class="highlight"><b>10:15–11:00</b></td>-->
    <!--      <td colspan="4" class="special">Coffee Break</td>-->
    <!--    </tr>-->
    <!--    <tr>-->
    <!--      <td class="highlight"><b>11:00–12:30</b></td>-->
    <!--      <td>Studios and GSC session</td>-->
    <!--      <td>Session: Tangible Textiles</td>-->
    <!--      <td>Session: Elemental & Biomaterial</td>-->
    <!--      <td>Session: The More, the Merrier</td>-->
    <!--      &lt;!&ndash;      <td class="special">SPORTS</td>&ndash;&gt;-->
    <!--    </tr>-->
    <!--    <tr>-->
    <!--      <td class="highlight"><b>12:30–14:00</b></td>-->
    <!--      <td class="special">Lunch<br><span style="font-weight: normal; font-size: smaller">(not provided)</span></td>-->
    <!--      <td class="special">Diversity Lunch</td>-->
    <!--      <td colspan="2" class="special">Lunch <br><span style="font-weight: normal; font-size: smaller">(not provided)</span></td>-->
    <!--    </tr>-->
    <!--    <tr>-->
    <!--      <td class="highlight"><b>14:00–15:45</b></td>-->
    <!--      <td>Studios and GSC session</td>-->
    <!--      <td>Session: Haptics</td>-->
    <!--      <td>Session: Paper-based Interaction</td>-->
    <!--      <td>TEI Townhall meeting</td>-->
    <!--    </tr>-->
    <!--    <tr>-->
    <!--      <td class="highlight"><b>15:45–16:00</b></td>-->
    <!--      <td colspan="4" class="special">Coffee Break</td>-->
    <!--    </tr>-->
    <!--    <tr>-->
    <!--      <td class="highlight"><b>16:00–17:45</b></td>-->
    <!--      <td>Studios and GSC session</td>-->
    <!--      <td>Session: Spirituality, Nature and Place</td>-->
    <!--      <td>no plenary session (demo and art track setup @CAPC)</td>-->
    <!--      <td>Closing Panel</td>-->
    <!--    </tr>-->

    <!--    <tr>-->
    <!--      <td class="special"> </td>-->
    <!--      <td  class="special">18:00–20:00<br> Welcome reception in <a href="https://maps.app.goo.gl/BjFkPvo71HWBfjpbA" target="_blank">City Townhall of Bordeaux </a></td>-->
    <!--      <td  class="special">18:00–20:00 <br> Student Design Competition </td>-->
    <!--      <td class="special">19:00–00:00<br> Demo and art track reception in <a href="https://maps.app.goo.gl/pUbiipqARyHoPDde8" target="_blank">CAPC (Museum of Contemporary Art)</a></td>-->
    <!--      <td class="special"></td>-->

    <!--    </tr>-->
    <!--  </table>-->
    </body>
  </main>
</template>
<!--<script type="module" src="../../populateCalendar.js"></script>-->
<script setup>

</script>

<style scoped>
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

table {
  border-collapse: collapse;
  margin: 20px auto;
  background-color: var(--background);
  border: 2px solid var(--textlight);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

th,
td {
  border: 1px solid var(--textlight);;
  padding: 10px;
  text-align: center;
}

td {
  background-color: var(--background);
}

th {
  background-color: var(--background-alt);
  color: var(--text);
}

.highlight {
  background-color: var(--foreground);
}

.special {
  background-color: var(--foreground);
  font-weight: bold;

}

.chart {
  display: grid;
  grid-template-rows: repeat(100, 1fr); /* 1 row = 1%! */
}

.fifty-percent-bar {
  grid-row: 51 / 101; /* 101 - 51 = 50 => 50% */
}

.session {
  padding: .5em;
  border-radius: 2px;
  font-size: 14px;
  box-shadow:
      rgba(255,255,255,.6) 1px 1px 0,
      rgba(0,0,0,.3) 4px 4px 0;
  margin-top:0.25rem;
  margin-bottom:0.25rem;
  margin-right:0.5rem;
  margin-left:0.5rem;
}

.schedule {
  display: grid;
  grid-template-columns:
    [times] 4em
    [tuesday-start] 1fr
    [tuesday-end wednesday-start] 1fr
    [wednesday-end thursday-start] 1fr
    [thursday-end friday-start] 1fr
    [friday-end];
  grid-template-rows: /** https://css-tricks.com/building-a-conference-schedule-with-css-grid/ **/
      [tracks] auto
      [time-0800] 0.33fr
[time-0815] 0.5fr
[time-0830] 0.5fr
[time-0845] 0.5fr
[time-0900] 0.5fr
[time-0915] 0.5fr
[time-0930] 0.5fr
[time-0945] 0.5fr
[time-1000] 0.5fr
[time-1015] 0.5fr
[time-1030] 0.5fr
[time-1045] 0.5fr
[time-1100] 0.5fr
[time-1115] 0.5fr
[time-1130] 0.5fr
[time-1145] 0.5fr
[time-1200] 0.5fr
[time-1215] 0.5fr
[time-1230] 0.5fr
[time-1245] 0.5fr
[time-1300] 0.5fr
[time-1315] 0.5fr
[time-1330] 0.5fr
[time-1345] 0.5fr
[time-1400] 0.5fr
[time-1415] 0.5fr
[time-1430] 0.5fr
[time-1445] 0.5fr
[time-1500] 0.5fr
[time-1515] 0.5fr
[time-1530] 0.5fr
[time-1545] 0.5fr
[time-1600] 0.5fr
[time-1615] 0.5fr
[time-1630] 0.5fr
[time-1645] 0.5fr
[time-1700] 0.5fr
[time-1715] 0.5fr
[time-1730] 0.5fr
[time-1745] 0.5fr
[time-1800] 0.5fr
[time-1815] auto
[time-1830] auto
[time-1845] auto
[time-1900] auto
[time-1915] auto
[time-1930] auto
[time-1945] auto
[time-2000] auto
[time-2015] auto
[time-2030] auto
[time-2045] auto
[time-2100] auto
[time-2115] auto
[time-2130] auto
[time-2145] auto
[time-2200] auto
[time-2215] auto
[time-2230] auto
[time-2245] auto
[time-2300] auto
[time-2315] auto
[time-2330] auto
[time-2345] auto
[time-0000] auto
}

.studios {
  background: #FFD6EA;
}
.events {
  background: #FFE5AB;
}
.main {
  background: #F5F5F5;
}

.papers {
  background: #A3EC9C;
}

.SDC {
  background: #6BAED6;
}

.time-slot {
  grid-column: times;
}
.track-slot,
.time-slot {
  font-weight: bold;
  font-size:.75em;
  text-align: center;
}
.session-title {
  font-weight: bold;
  font-size:1.12em;
}
</style>
