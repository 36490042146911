<template>

<!--  <component :is="websiteLink.length ? 'a' : 'span'" :href="websiteLink">{{name}}-->
<!--    <img class="sponsor" :is="option.length ? class='{{option}}': " :src="getImgSrc('')"/>-->
<!--    <img class="sponsor" :src="getImgSrc('')"/>-->
<!--    :class="[{'option.length': true}, sponsor  ? 'fa-' + icon : '']"-->
  <a :href="websiteLink" target="_blank">
    <img :class="['sponsor', optionClass]" :src="getImgSrc('')" :title="name"/>
  </a>
<!--  </component>-->

</template>
<!--  Photo by <component :is="websiteLink.length ? 'a' : 'span'" :href="authorLink">{{author}}</component><template v-if="website.length"> on <a :href="websiteLink">{{website}}</a></template>-->

<!--</template>-->

<script setup>
const props = defineProps(['name', 'img', 'type', 'level', 'websiteLink','optionClass'])
// defineProps(['author', 'authorLink', 'website', 'websiteLink'])

function getImgSrc (min) {
  return require('@/assets/sponsors/' + props.img + '.webp')
}
// export default {
//   name: 'SponsorComponent'
// }
</script>

<style scoped>


img.sponsor {
  max-height: 80px;
  max-width: 170px;
  margin: 5px;
}

img.sponsor.higher{
  max-height: 130px;
  max-width: 170px;
}

img.sponsor.smaller{
  max-height: 60px;
  max-width: 170px;
}

.sponsors-list {
  text-align: center;
}

@media (prefers-color-scheme: light) {
  img.invert {
    filter: invert(1);
  }
}
</style>
