<template>
<main>
  <h1>TEI 2025 Accepted Papers</h1>
  <p>We are happy to announce the accepted papers for TEI25.</p>

  <ul>
    <li>
      <span class="authors">Foroozan Daneshzand, Charles Perin, Navarjun Singh, Tatiana Losev, Sheelagh Carpendale.&nbsp;</span>
      <span class="title">Designing with Data: Supporting Design Processes in Physicalization Construction&nbsp;</span>
    </li>
    <li>
      <span class="authors">Wo Meijer, Jacky Bourgeois, Tilman Dingler, Gerd Kortuem.&nbsp;</span>
      <span class="title">Tangi: a Tool to Create Tangible Artifacts for Sharing Insights from 360° Video&nbsp;</span>
    </li>
    <li>
      <span class="authors">Riku Kitamura, Kenji Yamada, Takumi Yamamoto, Yuta Sugiura.&nbsp;</span>
      <span class="title">Ambient Display utilizing Anisotropy of Tatami&nbsp;</span>
    </li>
    <li>
      <span class="authors">Robert B. Markum, Ann Arbor.&nbsp;</span>
      <span class="title">On the design of technosacred spaces&nbsp;</span>
    </li>
    <li>
      <span class="authors">Kiryu Tsujita, Takatoshi Yoshida, Kohei Kobayashi, Nobuhisa Hanamitsu, Kanghong Zhong, Arata Horie, Kouta Minamizawa.&nbsp;</span>
      <span class="title">HaptoRoom: Designing Spatial and Reconfigurable Haptic Experience using Vibrotactile Floor Interface&nbsp;</span>
    </li>
    <li>
      <span class="authors">Tamara Friedenberger, Jörn Hurtienne.&nbsp;</span>
      <span class="title">Size Doesn’t Matter: Down-Scaling a Data Physicalization Does Not Change Social Engagement of Viewers&nbsp;</span>
    </li>
    <li>
      <span class="authors">Kyung Yun Choi, Taehee Jung, Noble Costales Harasha, Hiroshi Ishii.&nbsp;</span>
      <span class="title">Picto: Crafting Remote Tangible Gestures via Recordable, Replayable, and Shareable Motions &nbsp;</span>
    </li>
    <li>
      <span class="authors">Bhaskar Dutt, Yixuan Chen, Daniel Ashbrook, Valkyrie Savage.&nbsp;</span>
      <span class="title">Impedius: A Signal-Space Multiplexing Technique Using Individual Elements of Impedance for Chained Passive Sensors&nbsp;</span>
    </li>
    <li>
      <span class="authors">Osazuwa John Okundaye-Santos, Kamal Poluri, Aryabhat Darnal, Anastasia Hanifah Muliana, Jeeeun Kim.&nbsp;</span>
      <span class="title">3D Printed Kerf Structures&nbsp;</span>
    </li>
    <li>
      <span class="authors">Semina Yi, Daragh Byrne, Dina EL-Zanfaly.&nbsp;</span>
      <span class="title">AirCraft: Exploring Air as a Design Medium&nbsp;</span>
    </li>
    <li>
      <span class="authors">Ata Otaran, Yu Jiang, Jürgen Steimle.&nbsp;</span>
      <span class="title">Sparsely actuated modular metamaterials for shape changing interfaces&nbsp;</span>
    </li>
    <li>
      <span class="authors">Han Huang, Tingyu Cheng, Jinzhi Ye, Yuhui Zhao, Yiyang Wang, Haiqing Xu, Abigale Stangl, HyunJoo Oh.&nbsp;</span>
      <span class="title">Eliciting Design Guidelines of Paper-Based Tactile Interfaces for Eyes-Free Scenarios&nbsp;</span>
    </li>
    <li>
      <span class="authors">Pedro Ferreira, Marta Galvão Ferreira, Teresa Maritza Gouveia da Silva, Nuno Jardim Nunes, Valentina Nisi.&nbsp;</span>
      <span class="title">The entangled tales that landscapes tell: An embodied HCI pedagogy for re-enchanted nature walks&nbsp;</span>
    </li>
    <li>
      <span class="authors">Maxime Daniel, William Delamare.&nbsp;</span>
      <span class="title">ShyPins: Safeguarding User Mental Safety From The Forcible (Dis)Appearance Of Pin-based Controls By Using Speed Zones
      &nbsp;</span>
    </li>
    <li>
      <span class="authors">Ylva Fernaeus.&nbsp;</span>
      <span class="title">With a Taste for Earth:  Playing with the Aesthetics of Post-Industrial High-Tech&nbsp;</span>
    </li>
    <li>
      <span class="authors">Sora Oka, Kazuki Koyama, Tomoyuki Gondo, Yasushi Ikeda, Yoshihiro Kawahara, Koya Narumi.&nbsp;</span>
      <span class="title">Pneumatic Laser Origami: Rapid and Large-Scale Fabrication of Laser-Welded Pouch Motors for Shape-Changing Products&nbsp;</span>
    </li>
    <li>
      <span class="authors">Yuhan Wang, Keru Wang, Zhu Wang, Ken Perlin.&nbsp;</span>
      <span class="title">RoboTerrain: A Modular Shape-changing Interface Using Actuated Support Beams
      &nbsp;</span>
    </li>
    <li>
      <span class="authors">Lee Jones, Greta Grip, GHY Cheung, Sara Nabil.&nbsp;</span>
      <span class="title">``It's being unmade!'': Reflections on the Unraveling and (de)fabrication of Machine-Knit Textile Tapestries as Interactive Public Displays&nbsp;</span>
    </li>
    <li>
      <span class="authors">Kanon Aoyama, Takafumi Moritan, Ziyuan Jiang, Yu Kuwajima, Tomoka Kurosawa, Naoki Hosoya, Shingo Maeda, Yasuaki Kakehi.&nbsp;</span>
      <span class="title">Liquebits: Colored Expressive Control of Liquid Fiber based on Electrohydrodynamics Actuation&nbsp;</span>
    </li>
    <li>
      <span class="authors">Leijing Zhou, Yiqing Zhang, Xin An, Junxian Li.&nbsp;</span>
      <span class="title">E-scent Coach: A Wearable Olfactory System to Guide Deep Breathing Synchronized with Yoga Postures&nbsp;</span>
    </li>
    <li>
      <span class="authors">Lina Zhang, Huai Yibin, Zhian Hu, Jiayi Wu, Alvaro Cassinelli, Kening Zhu. &nbsp;</span>
      <span class="title">ThreadTessel: A Modularized Tangible Toolkit Leveraging Origami Tessellation for Designing Thread-actuated Shape-changing Structures
&nbsp;</span>
    </li>
    <li>
      <span class="authors">Oliver Nowak, René Schäfer, Elisabeth Jane Buttkus, Lea Emilia Schirp, Heiko Müller, Jan Borchers.&nbsp;</span>
      <span class="title">Investigating Eyes-Free Recognition and Distinguishability of Textile Icons in Pairs&nbsp;</span>
    </li>
    <li>
      <span class="authors">Jingxin Yu, Amid Ayobi, Paul Marshall, Aisling Ann O'Kane.&nbsp;</span>
      <span class="title">Creatively Supporting Mental Health: A Tangible Toolkit to Scaffold Self-Tracking through Mindful Colouring&nbsp;</span>
    </li>
    <li>
      <span class="authors">Krithik Ranjan, Peter Gyory, Seneca Howell, Therese Stewart, Michael L. Rivera, Ellen Yi-Luen Do.&nbsp;</span>
      <span class="title">Cartoonimator: A Paper-based Tangible Kit for Keyframe Animation&nbsp;</span>
    </li>
    <li>
      <span class="authors">Nihar Sabnis, Ata Otaran, Dennis Wittchen, Johanna K. Didion, Jürgen Steimle, Paul Strohmeier.&nbsp;</span>
      <span class="title">Foot Pedal Control: The Role of Vibrotactile Feedback in Performance and Perceived Control&nbsp;</span>
    </li>
    <li>
      <span class="authors">Amy Melniczuk, Meng Liang, Julian Preissing.&nbsp;</span>
      <span class="title">Exploring Tangible Designs to Improve Interpersonal Connectedness in Remote Group Brainstorming&nbsp;</span>
    </li>
    <li>
      <span class="authors">Mira Thieme, Rosa van Koningsbruggen, Dominic Potts, Eva Hornecker.&nbsp;</span>
      <span class="title">AR versus Physical Data Representations: A Comparison of User Engagement and Spatial Exploration Patterns.&nbsp;</span>
    </li>
    <li>
      <span class="authors">Christopher Liscio, Daniel G. Brown.&nbsp;</span>
      <span class="title">Helping Popular Musicians Learn by Ear: Analyzing Video Lessons to Inform the Design of Memory-Oriented Human-Recording Interactions&nbsp;</span>
    </li>
    <li>
      <span class="authors">Chang Xiao.&nbsp;</span>
      <span class="title">ReactFold: Towards Camera-based Tangible Interaction on Passive Paper Artifacts&nbsp;</span>
    </li>
    <li>
      <span class="authors">Fengyu Wang, Weijia Lin, Jiayu Yao, Shichao Huang, Jiayu Li, Zirui Wang, Shiqi Shu, Jiayi Ma, Sheng Yang, Jiacheng Cao, Yijun Zhao, Cheng Yao.&nbsp;</span>
      <span class="title">BioMingle: A Tangible Embodied Interaction System for Enhancing Neighborhood Interaction in Urban Community Public Spaces in China

&nbsp;</span>
    </li>
    <li>
      <span class="authors">Lee Jones, Molly Stewart, Sydney Alana Shereck, Joelle Lintag, Dallas Doherty, Bianca Bucchino, Sara Nabil.
&nbsp;</span>
      <span class="title">Interactive Bobbin Lace: Metal Thread History, Interviews with Lace Makers, and Material Explorations with E-textiles&nbsp;</span>
    </li>
    <li>
      <span class="authors">David Bertolo, Stéphanie Fleck, Camille Lemiere, Isabelle Pecci.&nbsp;</span>
      <span class="title">Application of Tangible User Interface in Health: A Scoping Review&nbsp;</span>
    </li>
    <li>
      <span class="authors">Aino Elina Ojala, Emmi Pouta, Ramyah Gowrishankar&nbsp;</span>
      <span class="title">Unveiling Kinetic Expression: Exploring Design Variables and Processes for Dynamic Woven Textiles&nbsp;</span>
    </li>
    <li>
      <span class="authors">Melanie Gruben, Ashley Sheil, Sanchari Das Ritchie, Michelle O'Keeffe, Jacob Camilleri, Moya Cronin, Hazel Murray.&nbsp;</span>
      <span class="title">"It's Like Not Being Able to Read and Write": Narrowing the Digital Divide for Older Adults and Leveraging the Role of Digital Educators in Ireland&nbsp;</span>
    </li>
    <li>
      <span class="authors">Maudeline Marlier, Nicolas Renoir, Martin Hachet, Arnaud Prouzeau.&nbsp;</span>
      <span class="title">Exploring Interactions with Tangible and Actuated Tokens on a Shared Tabletop for Railway Traffic Management Control Centres&nbsp;</span>
    </li>
  </ul>

  <h2>Paper Chairs</h2>
  Leah Buechley, <i>University of New Mexico</i><br>
  HyunJoo Oh, <i>Georgia Tech</i><br>
  Paul Strohmeier, <i>Saarland University</i><br>
  <br>
</main>
</template>

<script setup>
</script>

<style scoped>
.title {
  font-weight: bold;
  margin-left: 2px;
}
</style>
