<script setup>

</script>

<template>
  <main>
    <h1>Keynote speaker</h1>

    <div class="two-columns">
      <div>
      <img src="@/assets/bt_img.webp">

      <h2>Barbara Tversky</h2>

      </div>
      <div>
        <br>
      <b>KEYNOTE ABSTRACT</b><br>
      All living creatures must move and act in space and with the things in it to survive. Those interactions are constantly tuned and refined, and coopted for other interactions. In people, and perhaps other living things, they form a or the foundation for abstract thought, audacious claims that will be supported. These interactions present challenges and opportunities for digital interactions and for AI.

      <br>
      <br>
      <b>ABOUT BARBARA TVERSKY</b><br>
      Barbara Tversky is a cognitive psychologist, Professor Emerita at Stanford University and Columbia Teachers College. She also held positions at Hebrew University of Jerusalem, the University of Michigan, Malardalen University and the Ecole des Hautes Etude en Sciences Sociales. Her research has spanned memory processes and distortions, categorization, spatial thinking and language, event perception and cognition, story-telling, diagrammatic reasoning, design, HCI, joint action, gesture, and creativity. She has enjoyed collaborations with computer scientists, linguists, neuroscientists, domain scientists, designers, and artists. She has served on many editorial and governing boards, nationally and internationally, and was president of the Association of Psychological Science. She is a fellow of the Cognitive Science Society, the Association of Psychological Science, the Russell Sage Foundation, and the Psychonomic Society. She is a recipient of the Kampe de Feriat Award and was elected to the Society for Experimental Psychology and the American Association of Arts and Science.
      </div>
    </div>
  </main>
</template>

<style scoped>
h2 {
  text-align: center;
}

img {
  display: block;
  width: 250px;
  height: 250px;
  object-fit: cover;
  border-radius: 100%;
  margin: auto;
}

@media (min-width: 800px) {
.two-columns {
  display: block;
  grid-template-columns: 200px auto;
  grid-gap: 40px;
}
}
</style>
