<template>
<main>
  <h1>Instructions for presenters</h1>
  <h2>In-person presenters</h2>
  The maximum length of your slot is 10 minutes, including questions. The next talk will start 15 minutes later and presenters need to start set up while the previous person answers questions. <i>We recommend a 7–8 minutes talk to leave time for some questions.</i>
</main>
</template>

<script>
export default {
  name: 'PresentersInstructions'
}
</script>

<style scoped>

</style>